import React, { Component } from "react";

// Containers
// import "./CookiePolicy.css";

// Material ui
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default class CookiePolicy extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <Container maxWidth="lg">
                <Grid container className="centered girdMarginTop">
                    <Grid item xs={12}>
                        <Typography className="headline" component="h1" variant="h4">
                            Nuclai cookie policy
                            </Typography>
                        <div className="ChangePassword" style={{marginTop: '20px'}}>
                            <Typography className="centered" variant="body1" gutterBottom>
                                A number of our pages use cookies to: <br /><br />

                                - Remember if you have agreed to our use of cookies on this site<br /><br />
                                - To anonymously gather statistics on number of visitors and behaviour.<br /><br />

                                Enabling these cookies is not strictly necessary for the website to work but it will provide you with a better browsing experience. <br />
                                You can delete or block these cookies, but if you do that some features of this site may not work as intended.
                                    The cookie-related information is not used to identify you personally and the pattern data is fully under our control. <br /><br />
                                These cookies are not used for any purpose other than those described here.
                                </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}
