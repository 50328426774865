import React, { Component, Fragment } from "react";

// Amplify
import { API } from "aws-amplify";

// Components
import LoaderButton from "../components/LoaderButton";

// Images
import appWalkthrough from '../images/nuclaiAppWalkthrough.gif';
import checkmark from '../images/checkmark.png';
import code from '../images/code.png';
import dataList from '../images/data_list.png';
import student from '../images/student.png';
import analysis from '../images/analysis.png';
import enterprise from '../images/enterprise.png';
import individual from '../images/tubes.png';
import microscope from '../images/microscope.png';
import measure from '../images/petri_dish.png';
import speed from '../images/speed.png';

import analyze from '../images/analyze_comp.gif';
import classifciation from '../images/classification_comp.gif';
import detection from '../images/detection_comp.gif';

// Internal
import './Home.css'
import cellTypes from '../cellTypes.json'

// Material ui
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// Material UI lab
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

// Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

const HayButton = withStyles(theme => ({
    root: {
        color: '#000',
        border: String('1px ') + 'solid ' + String('#000'),
        // backgroundColor: white[500],
        '&:hover': {
            color: 'white',
            backgroundColor: '#000',
        },
    },
}))(Button);
const filter = createFilterOptions();
export default class Home extends Component {
    constructor(props) {
        super(props);


        this.state = {
            animalArray: ['tubes.png', 'flask.png', 'dna.png', 'eppendorf.png', 'molecule.png', 'coat.png', 'tube.png', 'microscope.png', 'plant.png', 'pipett.png'],
            animalIconHeight: 40,
            checkmarkIcon: 30,
            cellTypes: cellTypes.cellTypes,
            chosenCellLine: { "name": "" },
            contactFormSent: false,
            contactUsDialog: false,
            contactTextField: "",
            contactEmailTextField: "",
            howItWorksIconHeight: 65,
            inviteFormSent: false,
            inviteDialog: false,
            inviteEmailField: "",
            language: this.props.language,
            rValue: "",
        }
    }

    // Handle addition of a cell line
    addCellLine(data) {
        return API.post("contact", "/add_cell_line", {
            body: data
        });
    }

    // Handle autocomplete
    handleAutocomplete = (event, value, type) => {

        let newState = { ...this.state }

        if (value === null) {
            newState[type] = {}
            this.setState(newState)
            return
        }

        if (value && value.inputValue) {
            newState[type].name = value.inputValue
            // Backend callback
            this.addCellLine({
                cellLine: value.inputValue,
                existing: false
            })


            this.setState(newState)
            return
        }

        // Backend callback
        this.addCellLine({
            cellLine: value.name,
            existing: true
        })

        this.setState({ [type]: value })

    }

    handleAutocompleteFilterOptions = (options, params) => {

        const filtered = filter(options, params);

        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
            });
        }
        return filtered;

    }

    // Handle the contact form
    handleContact = async (event, messageType, messageSent) => {

        event.preventDefault();

        // Start the isLoading
        this.setState({ isLoading: true });

        try {
            let googleResponse = await this.handleRecaptchaAPI({
                response: this.state.rValue
            })

            // If the response is true
            if (googleResponse['success']) {

                // Create backend object
                try {

                    if (messageType === 'invite') {
                        await this.sendContactInvite({
                            email: this.state.inviteEmailField,
                        })
                    } else {
                        await this.sendContactMessage({
                            messageContext: this.state.contactTextField,
                            email: this.state.contactEmailTextField,
                        })
                    }

                    this.resetInputFields()

                    // this.handleDialog(false, 'contactUsDialog')
                    this.setState({ isLoading: false, [messageSent]: true });


                } catch (e) {
                    alert(e);
                    this.setState({ isLoading: false });
                }

            } else {
                // Else show error message
                alert(googleResponse['error-codes']);
                this.setState({ isLoading: false });
            }
        } catch (e) {
            alert(e);
            this.setState({ isLoading: false });
        }

    }

    // Handle open and close dialog
    handleDialog = (status, dialogName) => {
        this.setState({ [dialogName]: status });
    }

    // Handle recaptcha
    handleRecaptcha = (value) => {
        if (value) {
            this.setState({ rValue: value });
        }

    }

    // Handle the recaptcha API
    handleRecaptchaAPI(recaptcha) {
        return API.post("contact", "/check_recaptcha", {
            body: recaptcha
        })
    }

    // Handle text input change
    handleTextInputChange = input => event => {
        this.setState({ ...this.state, [input]: event.target.value });
    };

    // Reset the text fields after message sent
    resetInputFields = async () => {

        // Time out for 5 seconds 
        await this.timer(5000);

        // Then reset
        this.setState({
            contactTextField: "",
            contactEmailTextField: "",
            inviteEmailField: "",
        })

    }

    // Send the contact form
    sendContactMessage(contactForm) {
        return API.post("contact", "/send_user_message", {
            body: contactForm
        });
    }
    // Send the contact form
    sendContactInvite(contactForm) {
        return API.post("contact", "/user_signup", {
            body: contactForm
        });
    }

    // Timer for handling timeouts
    timer(ms) {
        return new Promise(res => setTimeout(res, ms));
    }

    render() {
        return (
            <Fragment>
                {/* Header container start */}
                <Container maxWidth="md" className="headerContainerHeight">

                    {/* Including grid estart */}
                    <Grid container className="girdMarginTop">
                        <Grid item xs={12} className="imageMargin centered">
                            <img alt="" className="logoMargin logoSize" src={require('../images/logo.png')} />
                        </Grid>

                        <Grid item xs={12} className="centered">
                            <Typography className="sloganMargin appleFont headlineColor" variant="h4" gutterBottom>
                                {this.state.language.firstBlock.firstHeadline}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className="centered">
                            <HayButton className="buttonTopMargin" href="https://demo.nuclai.co/" size="large" target="_blank" variant="outlined">{this.state.language.firstBlock.freeDemo}</HayButton>

                        </Grid>

                    </Grid>
                </Container>
                {/* Header container end */}

                {/* A new way start */}
                <Container maxWidth="xl" className="girdMarginTop">

                    <Grid container>
                        <Grid item xs></Grid>
                        <Grid item xs={10} className="">
                            <Typography className="headlineColor centered sloganMargin appleFont sectionLargeHeadlineNoMargin hiddenWhenMobile" variant="h4" gutterBottom>
                                {this.state.language.aNewWay.partOne} <br />
                                {this.state.language.aNewWay.partTwo}
                            </Typography>
                            <Typography className="headlineColor sloganMargin appleFont sectionLargeHeadlineNoMargin hiddenWhenLarge" variant="h4" gutterBottom>
                                {this.state.language.aNewWay.partOne} <br />
                                {this.state.language.aNewWay.partTwo}
                            </Typography>
                            <Grid item xs={12} className="centered">
                                <HayButton className="buttonTopMargin" href="https://demo.nuclai.co/" size="large" target="_blank" variant="outlined">{this.state.language.firstBlock.freeDemo}</HayButton>

                            </Grid>
                            <Grid item xs className="centered ">
                                <img
                                    alt=""
                                    className="responsiveWalkthrough"
                                    src={appWalkthrough}
                                />
                            </Grid>

                        </Grid>
                        <Grid item xs ></Grid>
                    </Grid>
                </Container>
                {/* A new way end */}

                {/* How it works start */}
                <Container maxWidth="lg" className="girdMarginTop">

                    <Grid container justify="space-evenly">
                        <Grid item xs></Grid>
                        <Grid item xs={10} className="">
                            <Typography className="headlineColor sloganMargin appleFont sectionLargeHeadline" variant="h4" gutterBottom>
                                {this.state.language.howItWorks.headlinePartOne} <br />
                                {this.state.language.howItWorks.headlinePartTwo}
                            </Typography>
                        </Grid>
                        <Grid item xs ></Grid>
                    </Grid>


                    <Grid container spacing={2} className="centered">
                        <Grid item xs={12} md={4} className="descriptionMargin howItWorksGrid">
                            <img alt="" className="howItWorksImage" src={microscope} height={this.state.howItWorksIconHeight} />
                            <Typography className="headlineColor appleFont sectionHeadline" gutterBottom>
                                {this.state.language.howItWorks.howItWorksTitleOne}
                            </Typography>
                            <Typography className="appleFont sectionDescription" gutterBottom>
                                {this.state.language.howItWorks.howItWorksTextOne}
                            </Typography>


                        </Grid>
                        <Grid item xs={12} md={4} className="descriptionMargin howItWorksGrid">
                            <img alt="" className="howItWorksImage" src={measure} height={this.state.howItWorksIconHeight} />
                            <Typography className="headlineColor appleFont sectionHeadline" variant="body1" gutterBottom>
                                {this.state.language.howItWorks.howItWorksTitleTwo}
                            </Typography>
                            <Typography className="appleFont sectionDescription" gutterBottom>
                                {this.state.language.howItWorks.howItWorksTextTwo}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={4} className="descriptionMargin howItWorksGrid">
                            <img alt="" className="howItWorksImage" src={analysis} height={this.state.howItWorksIconHeight} />
                            <Typography className="headlineColor appleFont sectionHeadline" variant="body1" gutterBottom>
                                {this.state.language.howItWorks.howItWorksTitleThree}
                            </Typography>
                            <Typography className="appleFont sectionDescription" gutterBottom>
                                {this.state.language.howItWorks.howItWorksTextThree}
                            </Typography>

                        </Grid>
                    </Grid>
                </Container>
                {/* How it works end */}

                {/* For who start */}
                <Container data-target="#forWho" id="forWho" maxWidth="lg" className="girdMarginTop">

                    {/* Header container start */}
                    <Grid container justify="space-evenly">
                        <Grid item xs></Grid>
                        <Grid item xs={10} className="centered">
                            <Typography className="headlineColor sloganMargin appleFont sectionLargeHeadline" variant="h4" gutterBottom>
                                {this.state.language.forWho.forWhoTitle}<br />
                            </Typography>
                            {/* <img src={} height="c" /> */}
                            {this.state.animalArray.map(image =>
                                <img alt={image.split('.')[0]} key={image} src={require('../images/' + image)} height={this.state.animalIconHeight} />
                            )}
                            <Typography className="sectionDescriptionMarginTop appleFont" gutterBottom>
                                {this.state.language.forWho.forWhoText}<br />
                            </Typography>
                        </Grid>
                        <Grid item xs ></Grid>
                    </Grid>
                    {/* Header container end */}

                    {/* Try demo start */}
                    <Grid container justify="space-evenly" className="containerPadding">
                        <Grid item xs={6} className="centered">
                            <HayButton
                                className="createReportButton"
                                color="inherit"
                                href="https://demo.nuclai.co"
                                target="blank_"
                                variant="outlined"
                            >{this.state.language.forWho.tryOutForFree}</HayButton>
                        </Grid>
                    </Grid>
                </Container>
                {/* For who end */}

                {/* Steps start */}
                {/* Step 1 start */}
                <Container maxWidth="lg" className="headerContainerHeight">
                    <Grid container justify="space-evenly">
                        {/* Headline start */}
                        <Grid container >
                            <Grid item xs></Grid>
                            <Grid item xs={10} className="">
                                <Typography className=" appleFont sectionLargeHeadline sectionLargeHeadlineMargins" variant="h4" gutterBottom>
                                    {this.state.language.steps.headlinePartOne} <br />
                                    {this.state.language.steps.headlinePartTwo}
                                </Typography>
                            </Grid>
                            <Grid item xs ></Grid>
                        </Grid>
                        {/* Headline end */}
                        {/* Phone start */}
                        <Grid container justify="flex-end">
                            <Grid item sm={1}></Grid>
                            <Grid item xs={12} md={5} className="rightAligned gif--margin ">
                                <img
                                    alt=""
                                    className="responsiveStepByStep"
                                    // width={this.state.phoneWidth}
                                    src={classifciation}
                                />
                            </Grid>

                            <Grid item xs md={5} className="phoneTextPadding">
                                <Typography className="appleFont phoneDescriptionIphoneInRow sectionDescriptionGifLarge" gutterBottom>
                                    {this.state.language.steps.step1Headline} <br />

                                </Typography>
                                <Typography className="appleFont phoneDescriptionIphoneInRow" gutterBottom>
                                    {this.state.language.steps.step1Text}
                                </Typography>
                            </Grid>
                            <Grid item sm={1}></Grid>
                        </Grid>
                        {/* Phone end */}
                    </Grid>

                    {/* Step 1 end */}

                    {/* Step 2 start */}
                    <Grid container justify="space-evenly">
                        {/* Phone start */}
                        <Grid container justify="flex-end">
                            <Grid item sm={1}></Grid>
                            <Grid item xs className="phoneTextPadding hiddenWhenMobile">
                                <Typography md={5} className="appleFont phoneDescriptionIphoneInRow sectionDescriptionGifLarge" gutterBottom>
                                    {this.state.language.steps.step2Headline} <br />

                                </Typography>
                                <Typography className="appleFont phoneDescriptionIphoneInRow" gutterBottom>
                                    {this.state.language.steps.step2Text}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5} className="rightAligned gif--margin ">
                                <img
                                    alt=""
                                    className="responsiveStepByStep"
                                    src={detection}
                                />
                            </Grid>
                            <Grid item xs md={5} className="phoneTextPadding hiddenWhenLarge">
                                <Typography className="appleFont phoneDescriptionIphoneInRow sectionDescriptionGifLarge" gutterBottom>
                                    {this.state.language.steps.step2Headline} <br />
                                </Typography>
                                <Typography className="appleFont phoneDescriptionIphoneInRow" gutterBottom>
                                    {this.state.language.steps.step2Text}

                                </Typography>

                            </Grid>
                            <Grid item sm={1}></Grid>
                        </Grid>
                        {/* Phone end */}
                    </Grid>
                    {/* Step 2 end */}

                    {/* Step 3 start */}
                    <Grid container justify="space-evenly">
                        {/* Phone start */}
                        <Grid container justify="flex-end">
                            <Grid item sm={1} ></Grid>
                            <Grid item xs={12} md={5} className="rightAligned gif--margin ">
                                <img
                                    alt=""
                                    className="responsiveStepByStep"
                                    src={analyze}
                                />
                            </Grid>

                            <Grid item xs={12} md={5} className="phoneTextPadding">
                                <Typography className="appleFont phoneDescriptionIphoneInRow sectionDescriptionGifLarge" gutterBottom>
                                    {this.state.language.steps.step3Headline}  <br />
                                </Typography>
                                <Typography className="appleFont phoneDescriptionIphoneInRow" gutterBottom>
                                    {this.state.language.steps.step3Text}
                                </Typography>
                            </Grid>
                            <Grid item sm={1}></Grid>
                        </Grid>
                        {/* Phone end */}
                    </Grid>
                </Container>
                {/* Step 3 end */}
                {/* Steps end */}


                {/* Pricing start */}
                <Container data-target="#pricing" id="pricing" maxWidth="lg" className="girdMarginTop">
                    <Grid container justify="space-evenly">
                        <Grid item xs></Grid>
                        <Grid item xs={10} className="centered">
                            <Typography className="headlineColor sloganMargin appleFont sectionLargeHeadline" variant="h4" gutterBottom>
                                {this.state.language.pricing.pricingHeadlinePartOne} <br />
                                {this.state.language.pricing.pricingHeadlinePartTwo}
                            </Typography>
                        </Grid>
                        <Grid item xs ></Grid>
                    </Grid>
                    {/* Grad student pricing start */}
                    <Grid container spacing={2} className="centered">
                        <Grid item xs={12} md={4} className="descriptionMarginResponsive pricingGrid">
                            <Paper className="paperPadding">
                                <img alt="" className="pricingImage" src={student} height={this.state.howItWorksIconHeight} />
                                <Typography className="appleFont sectionHeadline" gutterBottom>
                                    {this.state.language.pricing.gradStudent.paymentHeadline}
                                </Typography>
                                <Typography className="appleFont sectionDescription" gutterBottom>
                                    {this.state.language.pricing.gradStudent.paymentText}
                                </Typography>
                                <Typography className="appleFont sectionDescription marginPricing" gutterBottom>
                                    <img alt="" className="checkmarkMargin" src={checkmark} height={this.state.checkmarkIcon} />
                                    {this.state.language.pricing.gradStudent.firstPoint}
                                </Typography>
                                <Typography className="appleFont sectionDescription marginPricing" gutterBottom>
                                    <img alt="" className="checkmarkMargin" src={checkmark} height={this.state.checkmarkIcon} />
                                    {this.state.language.pricing.gradStudent.secondPoint}
                                </Typography>
                                <Typography className="appleFont sectionDescription marginPricing" gutterBottom>
                                    <img alt="" className="checkmarkMargin" src={checkmark} height={this.state.checkmarkIcon} />
                                    {this.state.language.pricing.gradStudent.thirdPoint}
                                </Typography>

                                <br />
                                <HayButton
                                    className="getStartedButtonMargin"
                                    color="inherit"
                                    onClick={() => this.handleDialog(true, 'inviteDialog')}
                                    variant="outlined"
                                >
                                    {this.state.language.callToAction.freeAccount}
                                </HayButton>

                            </Paper>
                        </Grid>
                        {/* Individual pricing start */}
                        <Grid item xs={12} md={4} className="descriptionMarginResponsive pricingGrid">
                            <Paper className="paperPadding">
                                <img alt="" className="pricingImage" src={individual} height={this.state.howItWorksIconHeight} />
                                <Typography className="appleFont sectionHeadline" gutterBottom>
                                    {this.state.language.pricing.individual.paymentHeadline}
                                </Typography>
                                <Typography className="appleFont sectionDescription" gutterBottom>
                                    {this.state.language.pricing.individual.paymentText}
                                </Typography>
                                <Typography className="appleFont sectionDescription marginPricing" gutterBottom>
                                    <img alt="" className="checkmarkMargin" src={checkmark} height={this.state.checkmarkIcon} />
                                    <span style={{ color: 'black' }}>{this.state.language.pricing.individual.firstPoint}</span>
                                </Typography>
                                <Typography className="appleFont sectionDescription marginPricing" gutterBottom>
                                    <img alt="" className="checkmarkMargin" src={checkmark} height={this.state.checkmarkIcon} />
                                    {this.state.language.pricing.individual.secondPoint}
                                </Typography>
                                <Typography className="appleFont sectionDescription marginPricing" gutterBottom>
                                    <img alt="" className="checkmarkMargin" src={checkmark} height={this.state.checkmarkIcon} />
                                    {this.state.language.pricing.individual.thirdPoint}
                                </Typography>
                                <Typography className="appleFont sectionDescription marginPricing" gutterBottom>
                                    <img alt="" className="checkmarkMargin" src={checkmark} height={this.state.checkmarkIcon} />
                                    {this.state.language.pricing.individual.fourthPoint}
                                </Typography>

                                <br />
                                <HayButton
                                    className="getStartedButtonMargin"
                                    color="inherit"
                                    onClick={() => this.handleDialog(true, 'inviteDialog')}
                                    variant="outlined"
                                >
                                    {this.state.language.callToAction.freeAccount}
                                </HayButton>

                            </Paper>
                        </Grid>
                        {/* Enterprise pricing start */}
                        <Grid item xs={12} md={4} className="descriptionMarginResponsive pricingGrid">
                            <Paper className="paperPadding">
                                <img alt="" className="pricingImage" src={enterprise} height={this.state.howItWorksIconHeight} />
                                <Typography className="appleFont sectionHeadline" gutterBottom>
                                    {this.state.language.pricing.enterprise.paymentHeadline}
                                </Typography>
                                <Typography className="appleFont sectionDescription" gutterBottom>
                                    {this.state.language.pricing.enterprise.paymentText}
                                </Typography>
                                <Typography className="appleFont sectionDescription marginPricing" gutterBottom>
                                    <img alt="" className="checkmarkMargin" src={checkmark} height={this.state.checkmarkIcon} />
                                    <span style={{ color: 'black' }}>{this.state.language.pricing.enterprise.firstPoint}</span>
                                </Typography>
                                <Typography className="appleFont sectionDescription marginPricing" gutterBottom>
                                    <img alt="" className="checkmarkMargin" src={checkmark} height={this.state.checkmarkIcon} />
                                    {this.state.language.pricing.enterprise.secondPoint}
                                </Typography>
                                <Typography className="appleFont sectionDescription marginPricing" gutterBottom>
                                    <img alt="" className="checkmarkMargin" src={checkmark} height={this.state.checkmarkIcon} />
                                    {this.state.language.pricing.enterprise.thirdPoint}
                                </Typography>
                                <Typography className="appleFont sectionDescription marginPricing" gutterBottom>
                                    <img alt="" className="checkmarkMargin" src={checkmark} height={this.state.checkmarkIcon} />
                                    {this.state.language.pricing.enterprise.fourthPoint}
                                </Typography>

                                <br />
                                <HayButton
                                    className="getStartedButtonMargin"
                                    color="inherit"
                                    onClick={() => this.handleDialog(true, 'contactUsDialog')}
                                    variant="outlined"
                                >
                                    {this.state.language.pricing.enterprise.contactUsButtonText}
                                </HayButton>

                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                {/* Pricing end */}

                {/* Curious start */}
                <Container data-target="#curious" id="curious" maxWidth="lg" className="girdMarginTop">

                    {/* Header container start */}
                    <Grid container justify="space-evenly">
                        <Grid item xs></Grid>
                        <Grid item xs={10} className="centered">
                            <Typography className="sloganMargin appleFont sectionLargeHeadline" variant="h4" gutterBottom>
                                {this.state.language.curious.curiousHeadline}<br />
                            </Typography>
                            <Typography className="sectionHeaderDescription appleFont" gutterBottom>
                                {this.state.language.curious.curiousTextPart1}<br />
                            </Typography>
                            <Grid container justify="space-evenly">
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        freeSolo
                                        options={this.state.cellTypes}
                                        getOptionLabel={option => option.name}
                                        filterOptions={(options, params) => this.handleAutocompleteFilterOptions(options, params)}
                                        getOptionLabel={option => {
                                            // e.g value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.name;
                                        }}
                                        onChange={(e, value) => this.handleAutocomplete(e, value, 'chosenCellLine')}
                                        renderInput={params => <TextField fullWidth {...params} label={this.state.language.curious.curiousAutocompleteLabel} variant="outlined" />}
                                        value={this.state.chosenCellLine}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs ></Grid>
                    </Grid>
                    {/* Header container end */}

                    {/* Demo button start */}
                    <Grid container justify="space-evenly" className="containerPadding">
                        <Grid item xs={6} className="centered">
                            <HayButton
                                className="tryForFreeButtonMargin getStartedButtonMargin"
                                color="inherit"
                                onClick={() => this.handleDialog(true, 'inviteDialog')}
                                variant="outlined"
                            >{this.state.language.callToAction.freeAccount}</HayButton>
                        </Grid>
                    </Grid>
                </Container>
                {/* Curious end */}

                {/* Why start */}
                <Container data-target="#why" id="why" maxWidth="lg" className="girdMarginTop">

                    <Grid container justify="space-evenly">
                        <Grid item xs></Grid>
                        <Grid item xs={10} className="">
                            <Typography className="headlineColor sloganMargin appleFont sectionLargeHeadline" variant="h4" gutterBottom>
                                {this.state.language.why.headlinePartOne} <br />
                                {this.state.language.why.headlinePartTwo}
                            </Typography>
                        </Grid>
                        <Grid item xs ></Grid>
                    </Grid>


                    <Grid container spacing={2} className="centered">
                        <Grid item xs={12} md={4} className="descriptionMargin howItWorksGrid">
                            <img alt="" className="howItWorksImage" src={dataList} height={this.state.howItWorksIconHeight} />
                            <Typography className="headlineColor appleFont sectionHeadline" gutterBottom>
                                {this.state.language.why.titleOne}
                            </Typography>
                            <Typography className="appleFont sectionDescription" gutterBottom>
                                {this.state.language.why.textOne}
                            </Typography>


                        </Grid>
                        <Grid item xs={12} md={4} className="descriptionMargin howItWorksGrid">
                            <img alt="" className="howItWorksImage" src={speed} height={this.state.howItWorksIconHeight} />
                            <Typography className="headlineColor appleFont sectionHeadline" variant="body1" gutterBottom>
                                {this.state.language.why.titleTwo}
                            </Typography>
                            <Typography className="appleFont sectionDescription" gutterBottom>
                                {this.state.language.why.textTwo}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={4} className="descriptionMargin howItWorksGrid">
                            <img alt="" className="howItWorksImage" src={code} height={this.state.howItWorksIconHeight} />
                            <Typography className="headlineColor appleFont sectionHeadline" variant="body1" gutterBottom>
                                {this.state.language.why.titleThree}
                            </Typography>
                            <Typography className="appleFont sectionDescription" gutterBottom>
                                {this.state.language.why.textThree}
                            </Typography>

                        </Grid>
                    </Grid>
                </Container>
                {/* Why end */}

                {/* Footer start */}
                <Container maxWidth={false} className="girdMarginTop footerBackground">
                    <Container maxWidth="md" className="girdMarginTop ">
                        <Grid container justify="space-evenly">
                            <Grid item xs>
                                <HayButton className="buttonTopMargin" color="inherit" onClick={() => this.handleDialog(true, 'contactUsDialog')} variant="outlined" >
                                    {this.state.language.footer.contactUs}
                                </HayButton>
                                <Typography className="typographyMarginTop" variant="body1" gutterBottom>
                                    {this.state.language.footer.whyHeadline}
                                </Typography>
                                <Typography className="" variant="body1" gutterBottom>
                                    {this.state.language.footer.aboutText}
                                </Typography>
                                <Typography className="typographyMarginTop" variant="body1" gutterBottom>
                                    {this.state.language.footer.aboutHeadline}
                                </Typography>

                                <Typography className="" variant="body1" gutterBottom>
                                    {this.state.language.forWho.forWhoText}
                                </Typography>
                                <Typography className="" variant="body1" gutterBottom >
                                    <a style={{ "color": "inherit" }} target="blank_" href="https://nuclai.co/policy">

                                        {this.state.language.footer.cookiePolicy}
                                    </a>
                                </Typography>
                                <Typography className="typographyMarginTop" variant="body1" gutterBottom>
                                    {this.state.language.footer.copyright}
                                </Typography>

                            </Grid>
                        </Grid>
                    </Container>
                </Container>
                {/* Footer end */}

                {/* Contact us dialog start */}
                <Dialog
                    fullWidth
                    open={this.state.contactUsDialog}
                    onClose={() => this.handleDialog(false, 'contactUsDialog')}
                    maxWidth="sm"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <span className="close" onClick={() => this.handleDialog(false, 'contactUsDialog')}>&times;</span>
                        <span className="dialogTitlePaddingSmaller appleFont sectionHeadlineDialog">{this.state.language.footer.contactUsTitle}</span></DialogTitle>
                    <DialogContent>
                        {!this.state.contactFormSent

                            ?
                            <Fragment>
                                <Typography className="appleFont sectionDescription" variant="h6" gutterBottom>
                                    {this.state.language.footer.contactUsText}
                                </Typography>
                                <TextField
                                    fullWidth
                                    component={'span'}
                                    id="outlined-multiline-static"
                                    label={this.state.language.footer.contactUs}
                                    margin="normal"
                                    multiline
                                    onChange={this.handleTextInputChange('contactTextField')}
                                    rows="4"
                                    value={this.state.contactTextField}
                                    variant="outlined"
                                />
                                <TextField
                                    fullWidth
                                    component={'span'}
                                    id="outlined-multiline-static"
                                    label={this.state.language.footer.contactUsEamail}
                                    margin="normal"
                                    onChange={this.handleTextInputChange('contactEmailTextField')}
                                    value={this.state.contactEmailTextField}
                                    variant="outlined"
                                />
                                <ReCAPTCHA
                                    sitekey="6LeiV-IUAAAAAFI2toK71EhJbdCbf0OPiYNHEYLg"
                                    onChange={this.handleRecaptcha}
                                />
                            </Fragment>
                            :
                            <Typography className="typographyMarginTop" variant="h6" gutterBottom>
                                {this.state.language.footer.contactUsMessage}
                            </Typography>
                        }
                    </DialogContent>
                    <DialogActions className="buttonMarginsDialog">
                        <Button
                            color="inherit"
                            disabled={this.state.isLoading || this.state.contactFormSent}
                            onClick={() => this.handleDialog(false, 'contactUsDialog')}
                            variant="outlined">
                            {this.state.language.footer.cancel}
                        </Button>

                        <LoaderButton
                            className="buttonLeftMargin"
                            color="inherit"
                            disabled={this.state.isLoading || !this.state.contactEmailTextField || (!this.state.contactFormSent && !this.state.rValue) || this.state.contactFormSent}
                            isLoading={this.state.isLoading}
                            loadingText={this.state.language.footer.sendingMessage}
                            onClick={(e) => this.handleContact(e, 'message', 'contactFormSent')}
                            text={this.state.language.footer.sendMessage}
                            type="button"
                            variant="outlined"
                        />
                    </DialogActions>
                </Dialog>

                {/* Contact us dialog end */}

                {/* Early invitation dialog start */}
                <Dialog
                    fullWidth
                    open={this.state.inviteDialog}
                    onClose={() => this.handleDialog(false, 'inviteDialog')}
                    maxWidth="sm"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <span className="close" onClick={() => this.handleDialog(false, 'inviteDialog')}>&times;</span>
                        <span className="dialogTitlePaddingSmaller appleFont sectionHeadlineDialog">{this.state.language.invite.inviteTitle}</span></DialogTitle>
                    <DialogContent>
                        {!this.state.inviteFormSent

                            ?
                            <Fragment>
                                <Typography className="appleFont sectionDescription" variant="h6" gutterBottom>
                                    {this.state.language.invite.inviteText}
                                </Typography>
                                <TextField
                                    fullWidth
                                    component={'span'}
                                    id="outlined-multiline-static"
                                    label={this.state.language.invite.inviteTextFieldPlaceholder}
                                    margin="normal"
                                    onChange={this.handleTextInputChange('inviteEmailField')}
                                    value={this.state.inviteEmailField}
                                    variant="outlined"
                                />
                                <ReCAPTCHA
                                    sitekey="6LeiV-IUAAAAAFI2toK71EhJbdCbf0OPiYNHEYLg"
                                    onChange={this.handleRecaptcha}
                                />
                            </Fragment>
                            :
                            <Typography className="typographyMarginTop" variant="h6" gutterBottom>
                                {this.state.language.invite.inviteSuccessUsMessage}
                            </Typography>
                        }
                    </DialogContent>
                    <DialogActions className="buttonMarginsDialog">
                        <Button
                            color="inherit"
                            disabled={this.state.isLoading || this.state.inviteFormSent}
                            onClick={() => this.handleDialog(false, 'inviteDialog')}
                            variant="outlined">
                            {this.state.language.footer.cancel}
                        </Button>

                        <LoaderButton
                            className="buttonLeftMargin"
                            color="inherit"
                            disabled={this.state.isLoading || !this.state.inviteEmailField || (!this.state.inviteFormSent && !this.state.rValue) || this.state.inviteFormSent}
                            isLoading={this.state.isLoading}
                            loadingText={this.state.language.invite.sendingMessage}
                            onClick={(e) => this.handleContact(e, 'invite', 'inviteFormSent')}
                            text={this.state.language.invite.sendMessage}
                            type="button"
                            variant="outlined"
                        />
                    </DialogActions>
                </Dialog>
                {/* Early invitation dialog end */}

            </Fragment>

        )
    }
}