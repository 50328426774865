const dev = {
    MAX_ATTACHMENT_SIZE: 5000000,
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://56j1gjwhbc.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
      REGION: "us-east-1",
      IDENTITY_POOL_ID: "us-east-1:3ff90ea6-caa9-47e6-ae73-774ab85a8d96"
    }
  }
  
  const prod = {
    MAX_ATTACHMENT_SIZE: 5000000,
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://56j1gjwhbc.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
      REGION: "us-east-1",
      IDENTITY_POOL_ID: "us-east-1:3ff90ea6-caa9-47e6-ae73-774ab85a8d96"
    }
  }
  
  // Default to dev if not set
  const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;
  
  
  export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };