// React
import React, { Component, Fragment } from 'react';
// import { Link } from "react-router-dom";
// import { Auth } from "aws-amplify";

// Scroll
import { Link } from "react-scroll";

// Components
import LoaderButton from "./components/LoaderButton";

// Containers
import './App.css';
import Routes from "./Routes";


// Form validator

// Material ui
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

import en from './translations/en.json'
import sv from './translations/sv.json'

// Select the language depending on stage
const selectedLanguage = process.env.REACT_APP_LANGUAGE_SELECTION === 'sv'
  ? sv
  : en

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: "",
      inviteDialog: false,
      inviteFormSent: false,
      inviteEmailField: "",
      language: selectedLanguage,
      logoHeight: 25,
      name: "",
      showMenu: true,

    }

  }

  async componentDidMount() {
    // await Auth.currentSession();

    // Check for menu
    if (window.location.pathname !== "/") {
      this.setState({ showMenu: false });

    }

    // Create the policy path
    let policyPathArray = window.location.pathname.split("/")


    if (policyPathArray[-1] === 'new') {
    }



  }
  // Handle open and close dialog
  handleDialog = (status, dialogName) => {
    this.setState({ [dialogName]: status });
  }

  // Handle menu click
  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  // Handle menu close
  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  openInNewTab(url) {
    this.handleMenuClose()
    var win = window.open(url, '_blank');
    win.focus();
  }

  // Handle text input change
  handleTextInputChange = input => event => {
    this.setState({ ...this.state, [input]: event.target.value });
  };

  // Go to each entry
  goTo(location) {
    this.props.history.push(location);
  }


  render() {
    const childProps = {
      language: this.state.language
    };
    return (
      <div className="textGrow">
        <AppBar position="fixed" className="menuStyling">
          <Toolbar>
            <a href="https://nuclai.co/">
              <img
                alt=""
                src={require('./images/logo_icon.png')}
                height={this.state.logoHeight}
                className="logoMargins"
              />
            </a>
            <span className="textGrow"></span>

            {this.state.showMenu &&
              <Fragment>

                {/* Try it out start */}
                <Link
                  color="inherit"
                  to="curious"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={700}
                >
                  < Button
                    className="showOnDesktop menuButtonMargin"
                    color="inherit"
                  >
                    {this.state.language.menu.tryItOut}
                  </Button>
                </Link>
                {/* Try it out end */}

                {/* For who start */}
                <Link
                  color="inherit"
                  to="forWho"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={700}
                >
                  < Button
                    className="showOnDesktop menuButtonMargin"
                    color="inherit"
                  >
                    {this.state.language.menu.forWho}
                  </Button>
                </Link>
                {/* For who end */}

                {/* Why start */}
                <Link
                  color="inherit"
                  to="why"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={700}
                >
                  < Button
                    className="showOnDesktop menuButtonMargin"
                    color="inherit"
                  >
                    {this.state.language.menu.why}
                  </Button>
                </Link>
                {/* Why end */}

                {/* Pricing start */}
                <Link
                  color="inherit"
                  to="pricing"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={700}
                >
                  < Button
                    className="showOnDesktop menuButtonMargin"
                    color="inherit"
                  >
                    {this.state.language.menu.pricing}
                  </Button>
                </Link>
                {/* Pricing end */}


                {/* Signup */}
                < Button
                  className="showOnDesktop menuButtonMargin"
                  color="inherit"
                  onClick={() => this.handleDialog(true, 'inviteDialog')}
                >
                  {this.state.language.menu.signin}
                </Button>

                {/* Demo */}
                <Button
                  className="showOnDesktop menuButtonMargin"
                  color="inherit"
                  variant="outlined"
                  href="https://demo.nuclai.co/"
                  target="_blank"
                >
                  {this.state.language.menu.demo}
                </Button>

                {/* Menu for small devices start */}
                <IconButton
                  // aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
                  onClick={(e) => this.handleMenuClick(e)}
                  className="showOnMobile"
                  aria-label="Menu">
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={() => this.handleMenuClose()}
                >
                  {/* Try it out start */}
                  <Link
                    color="inherit"
                    to="curious"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={700}
                  >
                    <MenuItem
                      onClick={() => this.handleMenuClose()}
                    >
                      {this.state.language.menu.tryItOut}
                    </MenuItem>
                  </Link>
                  {/* Try it out end */}

                  {/* For who start */}
                  <Link
                    color="inherit"
                    to="forWho"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={700}
                  >
                    <MenuItem
                      onClick={() => this.handleMenuClose()}
                    >
                      {this.state.language.menu.forWho}
                    </MenuItem>
                  </Link>
                  {/* For who end */}

                  {/* Why start */}
                  <Link
                    color="inherit"
                    to="why"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={700}
                  >
                    <MenuItem
                      onClick={() => this.handleMenuClose()}
                    >
                      {this.state.language.menu.why}
                    </MenuItem>
                  </Link>
                  {/* Why end */}

                  {/* Pricing start */}
                  <Link
                    color="inherit"
                    to="pricing"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={700}
                  >
                    <MenuItem
                      onClick={() => this.handleMenuClose()}
                    >
                      {this.state.language.menu.pricing}
                    </MenuItem>
                  </Link>
                  {/* Pricing end */}

                  {/* Menu item home */}
                  <MenuItem
                    // component={Link}
                    // onClick={() => this.handleMenuClose()}
                    onClick={() => this.handleDialog(true, 'inviteDialog')}
                  >
                    {this.state.language.menu.signin}
                  </MenuItem>

                  {/* Menu item FAQ */}
                  <MenuItem
                    // component={Link}
                    // onClick={() => this.handleMenuClose()}
                    onClick={(e) => { this.openInNewTab("https://demo.nuclai.co/") }}

                    href="https://demo.nuclai.co/"
                  >
                    {this.state.language.menu.demo}
                  </MenuItem>
                </Menu>
                {/* Early invitation dialog start */}
                <Dialog
                  fullWidth
                  open={this.state.inviteDialog}
                  onClose={() => this.handleDialog(false, 'inviteDialog')}
                  maxWidth="sm"
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <span className="close" onClick={() => this.handleDialog(false, 'inviteDialog')}>&times;</span>
                    <span className="dialogTitlePaddingSmaller appleFont sectionHeadlineDialog">{this.state.language.invite.inviteTitle}</span></DialogTitle>
                  <DialogContent>
                    {!this.state.inviteFormSent

                      ?
                      <Fragment>
                        <Typography className="appleFont sectionDescription" variant="h6" gutterBottom>
                          {this.state.language.invite.inviteText}
                        </Typography>
                        <TextField
                          fullWidth
                          component={'span'}
                          id="outlined-multiline-static"
                          label={this.state.language.invite.inviteTextFieldPlaceholder}
                          margin="normal"
                          onChange={this.handleTextInputChange('inviteEmailField')}
                          value={this.state.inviteEmailField}
                          variant="outlined"
                        />
                        <ReCAPTCHA
                          sitekey="6LeiV-IUAAAAAFI2toK71EhJbdCbf0OPiYNHEYLg"
                          onChange={this.handleRecaptcha}
                        />
                      </Fragment>
                      :
                      <Typography className="typographyMarginTop" variant="h6" gutterBottom>
                        {this.state.language.invite.inviteSuccessUsMessage}
                      </Typography>
                    }
                  </DialogContent>
                  <DialogActions className="buttonMarginsDialog">
                    <Button
                      color="inherit"
                      disabled={this.state.isLoading || this.state.inviteFormSent}
                      onClick={() => this.handleDialog(false, 'inviteDialog')}
                      variant="outlined">
                      {this.state.language.footer.cancel}
                    </Button>

                    <LoaderButton
                      className="buttonLeftMargin"
                      color="inherit"
                      disabled={this.state.isLoading || (!this.state.inviteFormSent && !this.state.rValue) || this.state.inviteFormSent}
                      isLoading={this.state.isLoading}
                      loadingText={this.state.language.footer.sendingMessage}
                      onClick={(e) => this.handleContact(e, 'invite', 'inviteFormSent')}
                      text={this.state.language.invite.sendMessage}
                      type="button"
                      variant="outlined"
                    />
                  </DialogActions>
                </Dialog>
                {/* Early invitation dialog end */}
              </Fragment>
            }

          </Toolbar>
        </AppBar>
        <Routes childProps={childProps} />
      </div >
    );
  }

}

export default App;
