import React from "react";
import "./LoaderButton.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';


export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <CircularProgress size={24} className="LoaderButton" />}
    {!isLoading ? text : loadingText}
  </Button>;