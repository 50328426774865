import React from "react";
import { Switch } from "react-router-dom";

// Components
import AppliedRoute from "./components/AppliedRoute";
import CookiePolicy from "./containers/CookiePolicy";


// Containers
import Home from "./containers/Home";




export default function Routes({ childProps }) {
    return (
        <Switch>
            <AppliedRoute path="/" exact component={Home} props={childProps} />
            <AppliedRoute path="/policy" exact component={CookiePolicy}  />


            { /* Finally, catch all unmatched routes */}
            {/* <Route component={NotFound} /> */}

        </Switch>
    )
}